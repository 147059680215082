import React from 'react'

const HelpConvertCsv: React.FC = (props) => {

    return (
        <>

            <div className='has-tooltip cursor-pointer mr-2'>

                <div className="tooltip text-xs shadow-lg bg-red-500 text-gray-50 py-2 px-2 rounded-sm">
                    <p className="p-4">
                        Caso o CSV exportado apresente falha nos caracteres, siga os passos abaixo:<br /><br />
                        1 - Baixe o arquivo;<br />
                        2 - Abra o mesmo no bloco de notas;<br />
                        3 - No bloco de notas, clique em Arquvio &gt; Salvar como ...<br />
                        4 - No nome do arquivo mantenha a extensão <b>.csv</b><br />
                        5 - Na opção "Tipo", escolha a codificação "UTF-8"<br />
                        6 - Clique em Salvar;<br />
                        7 - Abra o arquivo que acabou de salvar no Excel.
                    </p>
                </div>
                <svg aria-haspopup="true" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-circle text-red-500" width={18} height={18} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx={12} cy={12} r={9} />
                    <line x1={12} y1={8} x2="12.01" y2={8} />
                    <polyline points="11 12 12 12 12 16 13 16" />
                </svg>
            </div>
        </>

    );
};

export default HelpConvertCsv;


