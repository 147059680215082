import React from 'react'

import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import Divider from '../components/Divider'
import { useUser } from '../contexts/auth/UserProvider'
import FormSettingsCompany from './Settings/FormCompany'
import FormSettingsNotification from './Settings/FormNotification'

const Configuracoes: React.FC = () => {

    const { client } = useUser();

    return (
        <Layout>
            <PageTitle title='Configurações' />

            <div className="container mx-auto px-6">
                <div className="w-full h-64 rounded">
                    <div>
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Empresa</h3>
                                    <p className="mt-1 text-sm text-gray-600">
                                        {client?.nome_empresa}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 md:mt-0 md:col-span-2">
                                <FormSettingsCompany />
                            </div>
                        </div>
                    </div>

                    <Divider />

                    <div className="mt-10 sm:mt-0">
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Notificações</h3>
                                    <p className="mt-1 text-sm text-gray-600">
                                        Determine em qual mudança de status que será notificado
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 md:mt-0 md:col-span-2">
                                <FormSettingsNotification />
                            </div>
                        </div>
                    </div>
                    <Divider />

                </div>
            </div>
        </Layout >
    )
}

export default Configuracoes
