import React from 'react';
import moment from 'moment';
import maxByLodash from 'lodash/maxBy'

import { useTeacher } from '../../contexts/TeacherSelectedContext';
import { ExtrasDocenteProps, TitulosDocentesProps } from '../../models/ProfessorModel'
import ButtonUpdateTeacher from '../../components/ActionButton/UpdateTeacherApi'
import { getUfById } from '../../models/UfModels'

const Documentation: React.FC = (props) => {

    const { teacher } = useTeacher()
    const teacher_extras = (teacher?.pessoas_complementos ? maxByLodash(JSON.parse(teacher?.pessoas_complementos), 'data_cadastro') : {}) as ExtrasDocenteProps;
    const teacher_formation = (teacher?.titulos_docentes ? maxByLodash(JSON.parse(teacher?.titulos_docentes), 'grau') : {}) as TitulosDocentesProps;


    return (
        <>
            <div className="w-full py-10">
                <div className="container mx-auto px-6 flex items-start justify-center">
                    <div className="w-full">
                        <div className="flex flex-col lg:flex-row mx-auto bg-gray-100 dark:bg-gray-800 shadow rounded">
                            <div className="w-full lg:w-2/3 px-12 flex flex-col items-center py-10">
                                <div className="m-1 mr-2 shadow-solid rounded h-10 w-10 object-cover relative flex justify-center items-center bg-gray-500 text-xl text-white uppercase">
                                    {teacher?.professor?.charAt(0)}
                                </div>
                                <h2 className="text-gray-800 dark:text-gray-100 text-xl tracking-normal font-medium mb-1">
                                    {teacher?.professor.toUpperCase()}
                                </h2>
                                <p className="flex card-font mb-3 text-center">
                                    <span className="cursor-pointer mr-1 text-gray-600 dark:text-gray-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-pin" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <circle cx={12} cy={11} r={3} />
                                            <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 0 1 -2.827 0l-4.244-4.243a8 8 0 1 1 11.314 0z" />
                                        </svg>
                                    </span>
                                    {teacher_extras?.link_curriculo}
                                </p>
                                <p className="card-font mb-8 text-justify w-10/12">
                                    {teacher_extras?.mini_curriculo.replace(/(<([^>]+)>)/gi, "")}
                                </p>
                                <div className="flex items-start">
                                    <div>
                                        <p className="text-gray-800 dark:text-gray-100 text-sm leading-5">{teacher?.email.toLocaleLowerCase()}</p>
                                    </div>
                                    <div className="mx-6 lg:mx-3 xl:mx-6 px-8 lg:px-4 xl:px-8 border-l">
                                        <p className="text-gray-800 dark:text-gray-100 text-sm leading-5">{teacher?.cpf}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/3 px-12 border-t border-b lg:border-t-0 lg:border-b-0 lg:border-l lg:border-r border-gray-300 flex flex-col items-center py-10">
                                <div className="mb-3 w-24 h-24 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer text-indigo-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-stack" width={48} height={48} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <polyline points="12 4 4 8 12 12 20 8 12 4" />
                                        <polyline points="4 12 12 16 20 12" />
                                        <polyline points="4 16 12 20 20 16" />
                                    </svg>
                                </div>
                                <h2 className="text-gray-800 dark:text-gray-100 text-xl tracking-normal text-center font-medium mb-1">Formação</h2>
                                <p className="card-font mb-3 text-center"> {teacher_formation?.descricao}</p>
                                <p className="card-font mb-8 text-center w-10/12">{teacher_formation?.instituicao}</p>
                                <p className="text-gray-600 dark:text-gray-100 text-sm tracking-normal font-bold mb-8 text-center w-10/12">{teacher_formation?.curso}</p>
                                <div className="flex items-start">
                                    <div className="bg-gray-200 text-gray-600 dark:text-gray-100 dark:bg-gray-700 rounded text-xs leading-3 py-2 px-3">{moment(teacher_formation?.data_conclusao).format('L')}</div>
                                    <div className="bg-gray-200 text-gray-600 dark:text-gray-100 dark:bg-gray-700 rounded mx-2 text-xs leading-3 py-2 px-3">{teacher_formation?.sigla}</div>
                                    <div className="bg-gray-200 text-gray-600 dark:text-gray-100 dark:bg-gray-700 rounded text-xs leading-3 py-2 px-3">
                                        {teacher_formation?.uf_id ? getUfById(Number(teacher_formation.uf_id))?.uf : '--'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center max-h-full pt-10">
                    <ButtonUpdateTeacher teacher={teacher} sizeIcon={20}>
                        Atualizar Docente
                    </ButtonUpdateTeacher>
                </div>
            </div>
        </>
    );
}

export default Documentation;