import React from "react";
import { menus } from '../../models/MenuModel'
import UserContext from '../../contexts/auth/UserContext'
import { useKeycloak } from '@react-keycloak/web'
import { Link, useLocation } from 'react-router-dom'

interface NavbarMobileProps {
    children?: React.ReactNode;
    setClose: (show: boolean) => void;
}

const NavbarMobile: React.FC<NavbarMobileProps> = (props) => {

    const { user, client } = React.useContext(UserContext)
    const { keycloak } = useKeycloak()
    const location = useLocation()

    return (
        <>
            <div className="flex flex-col justify-between h-full">
                <div className="px-6 pt-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center bg-gray-700 shadow-2xl rounded px-4 py-1">
                            <img
                                src={client?.logo}
                                alt="Logotipo"
                                width={150}
                                height={50}
                            />
                        </div>
                        <div id="cross" className="text-gray-800" onClick={() => props.setClose(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1={18} y1={6} x2={6} y2={18} />
                                <line x1={6} y1={6} x2={18} y2={18} />
                            </svg>
                        </div>
                    </div>
                    <ul className="f-m-m">
                        {menus.map((menu, index_menu) => (
                            <Link to={menu.url} key={index_menu}>
                                <li className="pt-8">
                                    <div className={`flex items-center ${location.pathname === menu.url ? 'text-indigo-500' : 'text-gray-500'}`}>
                                        <div className="w-5 h-5">
                                            <img alt={menu.title} src={menu.icon} />
                                        </div>
                                        <p className={`ml-3 text-lg`}>
                                            {menu.title}
                                        </p>
                                    </div>
                                </li>
                            </Link>
                        ))}
                    </ul>




                </div>
                <div className="w-full">
                    <div className="flex justify-center mb-4 w-full px-6">
                        <div className="relative w-full">
                            <div className="text-gray-500 absolute ml-4 inset-0 m-auto w-4 h-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={16} height={16} viewBox="0 0 24 24" strokeWidth={1} stroke="#A0AEC0" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <circle cx={10} cy={10} r={7} />
                                    <line x1={21} y1={21} x2={15} y2={15} />
                                </svg>
                            </div>
                            <input className="bg-gray-100 focus:outline-none rounded w-full text-sm text-gray-500 pl-10 py-2" type="text" placeholder="nome do aluno" />
                        </div>
                    </div>
                    <div className="border-t border-gray-300">
                        <div className="w-full flex items-center justify-between px-6 pt-1">
                            <div className="flex items-center">
                                <div className="m-1 mr-2 shadow-solid rounded h-10 w-10 object-cover relative flex justify-center items-center bg-gray-500 text-xl text-white uppercase">{user?.name?.charAt(0)}</div>
                                <p className="text-gray-800 text-base leading-4 ml-2">{user?.name.split(' ').slice(0, 1)}</p>
                            </div>
                            <ul className="flex">
                                <li className="cursor-pointer text-gray-500 pt-5 pb-3" onClick={() => keycloak?.logout()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-logout" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path>
                                        <path d="M7 12h14l-3 -3m0 6l3 -3"></path>
                                    </svg>
                                </li>
                                <li className="cursor-pointer text-gray-500 pt-5 pb-3 pl-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bell" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                                        <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                                    </svg>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavbarMobile;
