import React from 'react';

interface EditStatusProps {
    sizeIcon: number;
}

const EditStatus: React.FC<EditStatusProps & React.HTMLProps<HTMLButtonElement>> = (props) => {

    return (
        <button
            className="btn bg-orange-500 px-2 mr-1"
            title="Editar Status"
            onClick={props.onClick}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={props.sizeIcon}>
                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
            </svg>
            {props.children}
        </button>
    );
};

export default EditStatus;

