import React, { useState } from 'react'
import EditStatus from './EditStatus';
import HistoryStatus from './HistoryStatus';
import { EditStatusCertificacaoProps } from '../../models/StatusModel'
import { useCertificacao } from '../../contexts/CertificacaoSelectedContext';

const TabStatus: React.FC<EditStatusCertificacaoProps> = (props) => {

    const [activeTab, setActiveTab] = useState(1);
    const { certificacao } = useCertificacao()
    return (
        <>
            { certificacao?.usuario &&
                <div className="max-h-full text-sm text-gray-900">

                    <div className="mx-auto container flex items-center justify-center">
                        <ul className="w-full flex items-center pb-2 border-b border-gray-200">
                            <li onClick={() => setActiveTab(1)} className={activeTab === 1 ? "tab-active flex" : "tab flex"}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={12}>
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg> <h4 className="pl-2">Editar</h4>
                            </li>
                            <li onClick={() => setActiveTab(2)} className={activeTab === 2 ? "tab-active ml-12 flex" : "tab ml-12 flex"}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="12" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <circle cx="12" cy="12" r="2"></circle>
                                    <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                </svg> <h4 className="pl-2">Histórico de Edições</h4>
                            </li>
                        </ul>
                    </div>
                </div>
            }

            { activeTab === 1 && <EditStatus handleSubmit={props.handleSubmit} />}
            { activeTab === 2 && <HistoryStatus />}
        </>
    );
};

export default TabStatus;