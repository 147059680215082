import React from 'react'
import useSWR from 'swr'
import moment from 'moment'
import { useTable, Column } from "react-table"

import Documentation from './Documentation'
import Layout from '../../components/Layout'
import PageTitle from '../../components/PageTitle'
import Filter from '../../components/Filter/teacher'
import Modal from '../../components/Modal'
import Pagination from '../../components/Pagination'
import Divider from '../../components/Divider'
import ActionButton from '../../components/ActionButtonTeacher'
import { Table } from '../../components/Table';
import { LoadingLine as Loading } from '../../components/Loading';
import { ProfessorProps as TeacherProps, ProfessorTableDataProps as TeacherTableDataProps } from '../../models/ProfessorModel'
import { FilterTeacherData, filterTeacherInitialValues } from '../../models/FilterModel'
import { useTeacher } from '../../contexts/TeacherSelectedContext'
import { fetcher } from '../../services/http'

const Docentes: React.FC = () => {

    const [search, setSearch] = React.useState<FilterTeacherData>(filterTeacherInitialValues)
    const [page, setPage] = React.useState(1)
    const [perPage, setPerPage] = React.useState(15)
    const [action, setAction] = React.useState<string>('')
    const { teacher, setTeacher, reload } = useTeacher()
    const { data, error } = useSWR(`/api/professores?page=${page}&per_page=${perPage}&search=${JSON.stringify(search)}&reload=${reload}`, fetcher)

    function handleFilter(filter: FilterTeacherData) {
        setPage(1);
        setSearch(filter);
    }

    function handleActionClick(action: string, teacher: TeacherProps) {
        setTeacher(teacher);
        setAction(action);
    }

    function handleCloseModal() {
        setTeacher(null);
        setAction('');
    }

    const handleChangePage = (page: number) => setPage(page);
    const handleSetPerPage = (perPage: number) => setPerPage(perPage);

    const createDataToTable = (dados: TeacherProps[]): TeacherTableDataProps[] => {
        const teachers: TeacherTableDataProps[] = [];

        dados?.forEach(function (item: TeacherProps) {
            teachers.push({
                id: item.id,
                professor: item.professor,
                pessoa_id: item.pessoa_id,
                cpf: item.cpf,
                email: item.email,
                titulos_docentes: item.titulos_docentes,
                pessoas_complementos: item.pessoas_complementos,
                created_at: moment(item.created_at).format('YYYY-MM-DD'),
                updated_at: moment(item.updated_at).format('YYYY-MM-DD HH:mm:ss'),
                view: <ActionButton handleActionClick={handleActionClick} teacher={item} />
            });
        });

        return teachers;
    }

    // eslint-disable-next-line
    const teachers = React.useMemo<TeacherTableDataProps[]>(() => createDataToTable(data?.data), [data?.data]);

    const columns = React.useMemo<Column<TeacherTableDataProps>[]>(() => [
        { Header: "ID", accessor: "id" },
        { Header: "ID", accessor: "pessoa_id" },
        { Header: "Docente", accessor: "professor", Cell: props => props.value.toLocaleUpperCase() },
        { Header: "CPF", accessor: "cpf" },
        { Header: "Email", accessor: "email", Cell: props => props.value.toLocaleLowerCase() },
        { Header: "Atualizado em", accessor: "updated_at", Cell: props => moment(props.value).format('L') },
        { Header: "Ações", accessor: "view" }
    ], []);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable<TeacherTableDataProps>({
        columns,
        data: teachers,
        initialState: {
            hiddenColumns: ['id']
        }
    });

    return (

        <Layout>
            <PageTitle title='Docentes' />
            <div className="container mx-auto px-6">
                <div className="w-full h-64 rounded">
                    <Filter handleFilter={handleFilter} />
                    <Divider />
                    <Table<TeacherTableDataProps>
                        getTableProps={getTableProps}
                        getTableBodyProps={getTableBodyProps}
                        headerGroups={headerGroups}
                        rows={rows}
                        prepareRow={prepareRow}
                    />
                    {!data && <Loading />}
                    {error && <div className='text-center text-sm text-red-600'>Erro ao buscar dados na api</div>}
                    {data?.meta && <Pagination info={data.meta} handleChangePage={handleChangePage} handleSetPerPage={handleSetPerPage} />}
                </div>
            </div>
            { action === 'view_documentacao' && teacher &&
                <Modal handleCloseModal={handleCloseModal} title={`Documentação do docente`}>
                    <Documentation />
                </Modal>
            }
        </Layout>
    )
}

export default Docentes;