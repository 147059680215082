// @flow
import React, { useContext } from "react";
import UserContext from "./UserContext";
import { useKeycloak } from "@react-keycloak/web";
import apiRequest from "../../services/http";

type Props = {};

export const UserProvider: React.FC<Props> = (props) => {
    const [user, setUser] = React.useState(null);
    const [client, setClient] = React.useState(null);
    const { keycloak } = useKeycloak();
    const isAuth = keycloak?.authenticated;

    React.useEffect(() => {
        if (!isAuth) {
            return;
        }
        (async () => {
            const response = await apiRequest.get("api/user")
            setUser(response?.data?.user);
            setClient(response?.data?.client);
        })();
    }, [isAuth]);

    return (
        <UserContext.Provider value={{ user, client }}>{props.children}</UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);