import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { PrivateRoute } from './utils';

import DashboardPage from '../pages/Dashboard';
import AcompanhamentoPage from '../pages/Acompanhamento';
import ConfiguracaoPage from '../pages/Configuracao';
import DocentePage from '../pages/Teacher';
import { LoadingScreen as Loading } from '../components/Loading';
import { TeacherProvider } from "../contexts/TeacherSelectedContext";
import { CertificacaoPreviewProvider } from "../contexts/CertificacaoPreviewSelected";
import { LoadingProvider } from "../contexts/LoadingContext";

export const Routes = () => {


    const { initialized } = useKeycloak();

    if (!initialized) {
        return <Loading />
    }

    return (
        <Router basename={'/certificacoes'}>
            <LoadingProvider>
                <PrivateRoute exact path="/" component={DashboardPage} />
                <CertificacaoPreviewProvider>
                    <PrivateRoute exact path="/acompanhamento" component={AcompanhamentoPage} />
                </CertificacaoPreviewProvider>
                <PrivateRoute exact path="/configuracoes" component={ConfiguracaoPage} />
                <TeacherProvider>
                    <PrivateRoute exact path="/docentes" component={DocentePage} />
                </TeacherProvider>
            </LoadingProvider>
        </Router>
    );
};

