import React from 'react';

interface ViewHistoricoButtonProps {
    sizeIcon: number;
}

const ViewHistoricoButton: React.FC<ViewHistoricoButtonProps & React.HTMLProps<HTMLButtonElement>> = (props) => {

    return (
        <button
            className="btn bg-cyan-500 px-2 mr-1"
            title="Ver histórico escolar"
            onClick={props.onClick}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={props.sizeIcon}>
                <path fillRule="evenodd" d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z" clipRule="evenodd" />
            </svg>
            {props.children}
        </button>
    );
};

export default ViewHistoricoButton;