import React from 'react';

interface DeleteCertificacaoButtonProps {
    sizeIcon: number;
}

const DeleteCertificacaoButton: React.FC<DeleteCertificacaoButtonProps & React.HTMLProps<HTMLButtonElement>> = (props) => {

    return (
        <button
            className="btn bg-red-500 px-2 mr-1"
            title="Deletar este resgistro"
            onClick={props.onClick}
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width={props.sizeIcon}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
            {props.children}
        </button>
    );
};

export default DeleteCertificacaoButton;