import React from 'react';
import { CertificacaoProps } from '../../models/CertificacaoModel'
import { useCertificacaoPreview } from '../../contexts/CertificacaoPreviewSelected'
import { useCertificacao } from '../../contexts/CertificacaoSelectedContext'

interface ViewCertificadoPreviewHtmlProps {
    certificacao: CertificacaoProps | null;
    sizeIcon: number;
}

const ViewCertificadoPreviewHtml: React.FC<ViewCertificadoPreviewHtmlProps> = (props) => {

    const [loading, setLoading] = React.useState(false);
    const { setCertificacaoPreview } = useCertificacaoPreview()
    const { setCertificacao } = useCertificacao()

    async function handleGetCertificadoHtml() {
        const url = `${process.env.REACT_APP_BACKEND_URL}certificacoes/${props.certificacao?.id}?output=html&zoom=55`
        setLoading(true)
        setCertificacaoPreview(url);
        setCertificacao(props.certificacao)
        setLoading(false)
    }

    return (
        <button
            className="btn bg-cyan-500 px-2 mr-1"
            title="Preview do Certificado - Para conferência"
            onClick={handleGetCertificadoHtml}
        >
            <svg className={loading ? 'animate-spin' : ''} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width={props.sizeIcon}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
            </svg>{props.children}
        </button>
    );
};

export default ViewCertificadoPreviewHtml