import React from 'react';
import moment from 'moment';

import { DocumantacaoAlunoProps } from '../../models/DocumentacaoModel'
import ButtonDownloadDocumentacao from '../../components/ActionButton/DownloadDocumentacao'
import ButtonUpdateDadosAluno from '../../components/ActionButton/UpdateDadosAluno'

import { useCertificacao } from '../../contexts/CertificacaoSelectedContext';

const Documentacao: React.FC = (props) => {

    const { certificacao } = useCertificacao()
    const documentos = certificacao?.documentos ? JSON.parse(certificacao?.documentos) : [];

    return (
        <>
            <div className="flex max-h-full text-sm text-gray-900">
                <div className="flex-1">
                    <div>
                        <span className="py-2 text-gray-700 font-bold">Aluno: </span> {certificacao?.aluno.toUpperCase()}
                    </div>
                    <div>
                        <span className="py-2 text-gray-700 font-bold">Data Conclusão: </span> {moment(certificacao?.data_conclusao).format('L')}
                    </div>
                </div>
                <div>
                    <div>
                        <span className="py-2 text-gray-700 font-bold">Curso: </span> {certificacao?.curso}
                    </div>
                    <div>
                        <span className="py-2 text-gray-700 font-bold">Carga Horária: </span> {certificacao?.carga_horaria}
                    </div>
                </div>
                <div>

                </div>
            </div>
            <div className="flex items-center justify-center max-h-full">
                <div className="overflow-auto w-full">
                    <table className="w-full flex flex-row flex-no wrap sm:bg-white rounded-lg sm:shadow-lg my-5">
                        <thead className="text-white">
                            <tr className="flex flex-col flex-no wrap sm:table-row rounded-lg mb-2 sm:mb-0">
                                <th className="p-3 text-left">Descriçao</th>
                                <th className="p-3 text-left">Arquivo</th>
                                <th className="p-3 text-left">Validado</th>
                            </tr>

                        </thead>

                        <tbody className="flex-1 sm:flex-none">
                            {
                                documentos.map(function (documento: DocumantacaoAlunoProps) {

                                    return (
                                        <tr
                                            key={documento.pessoa_arquivo_id}
                                            className={`flex flex-col flex-wrap sm:table-row mb-2 sm:mb-0 text-xs ${documento.valido === 'S' ? '' : 'bg-red-100'}`}
                                        >
                                            <td className="td-border">{documento.descricao} </td>
                                            <td className="td-border">{documento.arquivo} </td>
                                            <td className="td-border">{documento.valido} </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="flex items-center justify-center max-h-full pt-10">
                <ButtonDownloadDocumentacao certificacao={certificacao} sizeIcon={20}>
                    Baixar Documentação - ZIP
                </ButtonDownloadDocumentacao>
                {certificacao?.status_id !== 4 &&
                    <ButtonUpdateDadosAluno certificacao={certificacao} sizeIcon={20}>
                        Atualizar Documentação
                    </ButtonUpdateDadosAluno>
                }
            </div>
        </>
    )
}

export default Documentacao;