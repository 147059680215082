import React, { createContext, useContext } from "react";

import { CertificacaoProps } from '../models/CertificacaoModel'


export interface CertificacaoContextType {
    certificacao: CertificacaoProps | null;
    setCertificacao(data: CertificacaoProps | null): void;
}

interface Props { }

export const CertificacaoContext = createContext<CertificacaoContextType>({} as CertificacaoContextType);

export const CertificacaoProvider: React.FC<Props> = (props) => {
    const [certificacao, setNewCertificacao] = React.useState<CertificacaoProps | null>(null);
    const setCertificacao = (data: CertificacaoProps | null) => setNewCertificacao(data)

    return (
        <CertificacaoContext.Provider value={{ certificacao, setCertificacao }}>{props.children}</CertificacaoContext.Provider>
    );
};

export const useCertificacao = () => useContext(CertificacaoContext);

