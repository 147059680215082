import React from 'react'
import { CertificacaoTableDataProps } from '../../models/CertificacaoModel'
import { CSVLink } from "react-csv"
import minByLodash from 'lodash/minBy'

interface ActionButtonCheckedProps {
    handleActionClick: (status_id: number) => void;
    selectedCertificacoes: CertificacaoTableDataProps[];
    children?: React.ReactNode;
}

const ActionButtonChecked: React.FC<ActionButtonCheckedProps> = (props) => {

    const [loading, setLoading] = React.useState(false)
    const fileName = `alunos_aptos.csv`
    const headers = [
        { label: "Aluno", key: "aluno" },
        { label: "RA", key: "pessoa_id" },
        { label: "Curso", key: "curso" },
        { label: "Data Início", key: "data_inicio" },
        { label: "Data Conclusão", key: "data_conclusao" },
        { label: "Carga Horária", key: "carga_horaria" },
    ];

    const csvData = () => {
        return props.selectedCertificacoes.map((certificacao) => {
            const disciplinas = certificacao?.historico_escolar ? JSON.parse(certificacao?.historico_escolar) : [];
            // @ts-ignore
            certificacao.data_inicio = minByLodash(disciplinas, 'data_inicio')?.data_inicio?.toString();
            return certificacao;
        });
    }

    return (
        <div className="block" aria-hidden="true">
            <div className="py-2 sm:py-5">
                <button
                    onClick={() => props.handleActionClick(2)}
                    className="btn bg-indigo-500 px-2 mr-1"
                > Enviar para Impressão
                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" width={20}>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path>
                    </svg>
                </button>

                <CSVLink
                    data={csvData()}
                    headers={headers}
                    onClick={() => { setLoading(true); setTimeout(() => setLoading(false), 1000) }}
                    className="btn bg-purple-700 px-2 mr-1"
                    title="Baixar download - Histórico Escolar em CSV"
                    filename={fileName}
                >
                    Baixar download - Alunos Selecionados em CSV
                    <svg className={loading ? 'animate-spin' : ''} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width={20}>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                    </svg>
                </CSVLink>
            </div>
        </div>
    );
};

export default ActionButtonChecked;