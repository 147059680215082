import React from 'react'
import moment from 'moment'
import useSWR from 'swr'
import { useTable, Column } from "react-table";

import { certificacaoStatus } from '../../models/StatusModel'
import { useCertificacao } from '../../contexts/CertificacaoSelectedContext';
import { fetcher } from '../../services/http'
import { Table } from '../../components/Table';


interface HistoricoCertificacaoProps {
    id: string;
    status_id: number;
    usuario: string;
    updated_at: string;
}

interface HistoricoTableDataProps extends HistoricoCertificacaoProps {
    status: string | number;
}

const HistoricoCertificacao: React.FC = (props) => {

    const { certificacao } = useCertificacao()
    const { data } = useSWR(`/api/certificados-alteracoes?certificacao_id=${certificacao?.id}`, fetcher)
    const descricaoStatus = (status_id: number) => {
        const status = certificacaoStatus.filter((item) => {
            return item.id === status_id;
        });
        return status ? status[0].descricao : status_id;
    }

    const createDataToTable = (dados: HistoricoCertificacaoProps[]): HistoricoTableDataProps[] => {
        const historico: HistoricoTableDataProps[] = [];

        dados?.forEach(function (item: HistoricoCertificacaoProps) {
            historico.push({
                id: item.id,
                usuario: (item.usuario ?? 'Sistema').toLocaleUpperCase(),
                status_id: item.status_id,
                updated_at: moment(item.updated_at).format('LL'),
                status: descricaoStatus(item.status_id)
            });
        });

        return historico;
    }

    // eslint-disable-next-line
    const historicoCertificacao = React.useMemo<HistoricoTableDataProps[]>(() => createDataToTable(data?.data), [data?.data]);

    const columns = React.useMemo<Column<HistoricoTableDataProps>[]>(() => [
        { Header: "Data", accessor: "updated_at" },
        { Header: "Status", accessor: "status" },
        { Header: "Agente", accessor: "usuario" },
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable<HistoricoTableDataProps>({
        columns,
        data: historicoCertificacao,

    });

    if (!data) return <div className="text-center"><button type="button" className=" btn btn-orange">Carregando...</button></div>

    return (
        <div className="py-4">
            <Table<HistoricoTableDataProps>
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                rows={rows}
                prepareRow={prepareRow}
            />
        </div>
    );
};

export default HistoricoCertificacao;