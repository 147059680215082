import React from "react";
import MainContent from "../MainContent";
import Header from "../Header";

const Layout: React.FC = ({ children }) => {

  return (
    <>
      <div className="absolute bg-gray-200 w-full h-full">
        <Header />
        <MainContent>{children}</MainContent>
      </div>
    </>
  );
};

export default Layout;