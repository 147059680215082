import React from 'react';
import { CertificacaoProps } from '../../models/CertificacaoModel'
import ButtonDownloadCertificadoDigital from './DownloadCertificadoDigital'
import ButtonUpdateDadosAluno from './UpdateDadosAluno'
import EditStatus from './EditStatus'
import ViewDocumentacao from './ViewDocumentacao'
import ViewHistorico from './ViewHistorico'
import ViewCertificadoPreviewHtml from './ViewCertificadoPreviewHtml'
import DeleteCertificacao from './DeleteCertificacao'
import ButtonDownloads from './DownloadsButton'


interface ActionButtonProps {
    certificacao: CertificacaoProps;
    handleActionClick: (action: string, certificacao: CertificacaoProps) => void;
}

const sizeIcon = 10;
const statusCanPreviewHtml = [1, 9, 10, 11];

const ActionButton: React.FC<ActionButtonProps> = (props) => {

    return (
        <div className="block" aria-hidden="true">
            <div className="py-2 sm:py-5 flex flex-nowrap">
                <EditStatus onClick={() => props.handleActionClick('edit_certificacao', props.certificacao)} sizeIcon={sizeIcon} />
                <ViewDocumentacao onClick={() => props.handleActionClick('view_documentacao', props.certificacao)} sizeIcon={sizeIcon} />
                <ViewHistorico onClick={() => props.handleActionClick('view_historico', props.certificacao)} sizeIcon={sizeIcon} />
                {props.certificacao.status_id !== 4 && <ButtonDownloads certificacao={props.certificacao} sizeIcon={sizeIcon} />}
                {props.certificacao.status_id !== 4 && <ButtonUpdateDadosAluno certificacao={props.certificacao} sizeIcon={sizeIcon} />}
                {statusCanPreviewHtml.includes(props.certificacao.status_id) && <ViewCertificadoPreviewHtml certificacao={props.certificacao} sizeIcon={sizeIcon} />}
                {props.certificacao.certificado_digitalizado && <ButtonDownloadCertificadoDigital certificacao={props.certificacao} sizeIcon={sizeIcon} />}
                {props.certificacao.status_id !== 4 && <DeleteCertificacao onClick={() => props.handleActionClick('delete', props.certificacao)} sizeIcon={sizeIcon} />}

            </div>
        </div>
    );
};

export default ActionButton;