const Divider: React.FC = (props) => {
    return (
        <div className="block" aria-hidden="true">
            <div className="py-2 sm:py-5">
                <div className="border-t border-gray-200"></div>
            </div>
        </div>
    );
};

export default Divider;