import React, { createContext, useContext } from "react";

export interface LoadingContextType {
    loading: boolean;
    setLoading(data: boolean): void;
}

interface Props { }

export const LoadingContext = createContext<LoadingContextType>({} as LoadingContextType);

export const LoadingProvider: React.FC<Props> = (props) => {

    const [loading, setLoading] = React.useState<boolean>(false);
    return (
        <LoadingContext.Provider value={{ loading, setLoading }}>{props.children}</LoadingContext.Provider>
    );
};

export const useLoading = () => useContext(LoadingContext);

