import React from 'react';
import { Routes } from './routes';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { keycloakProviderInitConfig, keycloak } from './models/Keycloak'
import { UserProvider } from "./contexts/auth/UserProvider";
import { StatusProvider } from "./contexts/StatusSelectedContext";
import { CertificacaoProvider } from "./contexts/CertificacaoSelectedContext";
import { SnackbarProvider } from 'notistack';


function App() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakProviderInitConfig}
    >
      <SnackbarProvider
        maxSnack={3}
      >
        <UserProvider>
          <StatusProvider>
            <CertificacaoProvider>
              <Routes />
            </CertificacaoProvider>
          </StatusProvider>
        </UserProvider>
      </SnackbarProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
