import React from 'react';
import { CertificacaoProps } from '../../models/CertificacaoModel'
import apiRequest from '../../services/http';

interface ButtonDownloadCertificadoDigitalProps {
    certificacao: CertificacaoProps;
    sizeIcon: number;
}


const ButtonDownloadCertificadoDigital: React.FC<ButtonDownloadCertificadoDigitalProps> = (props) => {

    const [loading, setLoading] = React.useState(false)

    function handleDownload(certificacao: CertificacaoProps) {
        const fileName = certificacao.certificado_digitalizado.split('/')[2];
        const url = `api/${certificacao.certificado_digitalizado}`

        setLoading(true);

        apiRequest({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }).catch(erro => {
            console.log(erro);
        }).finally(() => setLoading(false));
    }


    return (
        <button
            className="btn bg-green-500 px-2 mr-1"
            onClick={() => handleDownload(props.certificacao)}
            title="Baixar Download - Certificado Digital"
            disabled={loading}
        >
            <svg className={loading ? 'animate-spin' : ''} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width={props.sizeIcon}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>{props.children}
        </button>
    );
};

export default ButtonDownloadCertificadoDigital;