import findLodash from 'lodash/find';

export const ufs = [
    { id: 12, uf: 'AC', uf_extenso: 'Acre' },
    { id: 27, uf: 'AL', uf_extenso: 'Alagoas' },
    { id: 16, uf: 'AP', uf_extenso: 'Amapá' },
    { id: 13, uf: 'AM', uf_extenso: 'Amazonas' },
    { id: 29, uf: 'BA', uf_extenso: 'Bahia' },
    { id: 23, uf: 'CE', uf_extenso: 'Ceará' },
    { id: 53, uf: 'DF', uf_extenso: 'Distrito Federal' },
    { id: 32, uf: 'ES', uf_extenso: 'Espírito Santo' },
    { id: 52, uf: 'GO', uf_extenso: 'Goiás' },
    { id: 21, uf: 'MA', uf_extenso: 'Maranhão' },
    { id: 51, uf: 'MT', uf_extenso: 'Mato Grosso' },
    { id: 50, uf: 'MS', uf_extenso: 'Mato Grosso do Sul' },
    { id: 31, uf: 'MG', uf_extenso: 'Minas Gerais' },
    { id: 41, uf: 'PR', uf_extenso: 'Paraná' },
    { id: 25, uf: 'PB', uf_extenso: 'Paraíba' },
    { id: 15, uf: 'PA', uf_extenso: 'Pará' },
    { id: 26, uf: 'PE', uf_extenso: 'Pernambuco' },
    { id: 22, uf: 'PI', uf_extenso: 'Piauí' },
    { id: 33, uf: 'RJ', uf_extenso: 'Rio de Janeiro' },
    { id: 24, uf: 'RN', uf_extenso: 'Rio Grande do Norte' },
    { id: 43, uf: 'RS', uf_extenso: 'Rio Grande do Sul' },
    { id: 11, uf: 'RO', uf_extenso: 'Rondonia' },
    { id: 14, uf: 'RR', uf_extenso: 'Roraima' },
    { id: 42, uf: 'SC', uf_extenso: 'Santa Catarina' },
    { id: 28, uf: 'SE', uf_extenso: 'Sergipe' },
    { id: 35, uf: 'SP', uf_extenso: 'São Paulo' },
    { id: 17, uf: 'TO', uf_extenso: 'Tocantins' },
    { id: 225, uf: 'PT', uf_extenso: 'Portugal' },
    { id: 226, uf: 'PAR', uf_extenso: 'Paraguai' },
    { id: 227, uf: 'ES', uf_extenso: 'Espanha' },
    { id: 228, uf: 'URU', uf_extenso: 'Uruguai' },
    { id: 229, uf: 'EU', uf_extenso: 'Estados Unidos da América' },
    { id: 230, uf: 'AR', uf_extenso: 'Argentin' },
    { id: 231, uf: 'CU', uf_extenso: 'Cuba' },
    { id: 232, uf: 'EX', uf_extenso: 'Caracas' },
];

export const getUfById = (id: number) => findLodash(ufs, { id: id })
