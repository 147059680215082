import React from 'react';

interface ModalProps {
    handleCloseModal: () => void;
    children: React.ReactNode;
    title?: string;
    fullscreen?: boolean
}

const Modal: React.FC<ModalProps> = (props) => {

    const fullscreen = props?.fullscreen === false ? props?.fullscreen : true;

    return (
        <div className="main-modal fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster bg-smoke-dark">
            <div className={fullscreen ? "w-full h-full mx-10  rounded-lg shadow-lg bg-white my-2" : "md:w-3/4 sm:w-full h-4/5 rounded-lg shadow-lg bg-white my-2"}>
                <div className="flex justify-between border-b border-gray-100 px-5 py-4">
                    <div>
                        <i className="fa fa-exclamation-triangle text-yellow-500"></i>
                        <span className="font-bold text-gray-700 text-lg">{props.title ?? 'Confirmar'}</span>
                    </div>
                    <div>
                        <button onClick={() => props.handleCloseModal()}>
                            <svg className="fill-current text-red-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                            </svg>
                        </button>
                    </div>
                </div>

                <div className="px-10 py-5 h-4/5 text-gray-600 modal-body">
                    {props.children}
                </div>

                <div className="pr-5 flex justify-items-end justify-end bg-gray-200">
                    <button className="btn btn-orange absolute bottom-2" onClick={() => props.handleCloseModal()}>Fechar</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;