import React, { createContext, useContext } from "react";

export interface CertificacaoPreviewContextType {
    certificacaoPreview: string;
    setCertificacaoPreview(data: string): void;
    reload: boolean;
    setReload(reload: boolean): void;
}

interface Props { }

export const CertificacaoPreviewContext = createContext<CertificacaoPreviewContextType>({} as CertificacaoPreviewContextType);

export const CertificacaoPreviewProvider: React.FC<Props> = (props) => {
    const [certificacaoPreview, setNewCertificacaoPreview] = React.useState<string>('');
    const [reload, setReload] = React.useState(false)
    const setCertificacaoPreview = (data: string) => setNewCertificacaoPreview(data)

    return (
        <CertificacaoPreviewContext.Provider value={{ certificacaoPreview, setCertificacaoPreview, reload, setReload }}>{props.children}</CertificacaoPreviewContext.Provider>
    );
};

export const useCertificacaoPreview = () => useContext(CertificacaoPreviewContext);