import React from 'react';
import moment from 'moment';
import mapLodash from 'lodash/map';
import uniqLodash from 'lodash/uniq';
import maxByLodash from 'lodash/maxBy';
import firstLodash from 'lodash/first'
import useSWR from 'swr'

import { fetcher } from '../../services/http'
import { HistoricoEscolarProps, UnidadeProps } from '../../models/CertificacaoModel'
import { ProfessorProps, TitulosDocentesProps, ProfessorDedicacaoExclusivaProps } from '../../models/ProfessorModel'
import ButtonDownloadCsvHistorico from '../../components/ActionButton/DownloadCsvHistorico'
import DownloadDocumentacaoProfessor from '../../components/ActionButton/DownloadDocumentacaoProfessor'
import DownloadDocumentacaoAluno from '../../components/ActionButton/DownloadDocumentacao'
import ButtonDownloadCertificadoDigital from '../../components/ActionButton/DownloadCertificadoDigital'
import ButtonCreateCertificadoPdf from '../../components/ActionButton/DownloadCertificadoPreviewPdf'
import ButtonUpdateDadosAluno from '../../components/ActionButton/UpdateDadosAluno'
import { useCertificacao } from '../../contexts/CertificacaoSelectedContext';
import HelpConvertCsv from '../../components/HelpConvertCsv'
import { LoadingLine as Loading } from '../../components/Loading';
import { getUfById } from '../../models/UfModels'

const HistoricoEscolar: React.FC = (props) => {

    const { certificacao } = useCertificacao()
    const disciplinas = certificacao?.historico_escolar ? JSON.parse(certificacao?.historico_escolar) : [];
    const professores_ids = uniqLodash(mapLodash(disciplinas, function (disciplina: HistoricoEscolarProps) {
        return Number(disciplina.professor_id);
    }));
    const { data: professores } = useSWR(`/api/professores?professor_id=${JSON.stringify(professores_ids)}`, fetcher);

    const metodologia = !certificacao?.com_tcc
        ? null
        : (disciplinas.filter((disciplina: HistoricoEscolarProps) => {
            return disciplina.tipo === 'M'
        }))[0] as HistoricoEscolarProps

    const unidade = !certificacao?.unidade
        ? null
        : JSON.parse(certificacao?.unidade) as UnidadeProps


    function getFormacaoProfessor(professor_id: number | null, professor_dedicacao_exclusiva: null | ProfessorDedicacaoExclusivaProps) {

        if (professor_dedicacao_exclusiva) {
            const grau = maxByLodash(professor_dedicacao_exclusiva.titulos_docentes_substituto, 'grau') as TitulosDocentesProps;
            return `${grau?.descricao} em ${grau?.curso} pela ${grau?.instituicao} ${getUfById(Number(grau?.uf_id))?.uf}/${moment(grau?.data_conclusao).format('Y')}`
        }

        const teachers = professores.data as ProfessorProps[];
        const teacher = firstLodash(teachers?.filter((teacher) => {
            return teacher.pessoa_id === professor_id;
        }));

        if (teacher) {
            const titulos_docentes = JSON.parse(teacher.titulos_docentes) as TitulosDocentesProps[];
            const grau = maxByLodash(titulos_docentes, 'grau');
            return `${grau?.descricao} em ${grau?.curso} pela ${grau?.instituicao} ${grau?.uf_id ? getUfById(Number(grau.uf_id))?.uf : '--'}/${moment(grau?.data_conclusao).format('Y')}`

        }
        return;
    }

    if (!professores) return <Loading />

    return (
        <>
            <div className="max-h-full text-sm text-gray-900">
                <div className="flex">
                    <div className="flex-1">
                        <span className="py-2 text-gray-700 font-bold">Aluno: </span> {certificacao?.aluno.toUpperCase()}
                    </div>
                    <div>
                        <span className="py-2 text-gray-700 font-bold">Data Conclusão: </span> {moment(certificacao?.data_conclusao).format('L')}
                    </div>
                </div>
                <div className="flex">
                    <div className="flex-1">
                        <span className="py-2 text-gray-700 font-bold">Curso: </span> {certificacao?.curso}
                    </div>
                    <div>
                        <span className="py-2 text-gray-700 font-bold">Carga Horária: </span> {certificacao?.carga_horaria}
                    </div>
                </div>

                {unidade &&
                    <div className="flex">
                        <div className="flex-1">
                            <span className="py-2 text-gray-700 font-bold">Unidade: </span> {unidade.nome}
                        </div>
                        <div>
                            <span className="py-2 text-gray-700 font-bold">Município Unidade: </span> {unidade.municipio}
                        </div>
                    </div>
                }

                {metodologia &&
                    <div className="flex">
                        <div className="flex-1">
                            <span className="py-2 text-gray-700 font-bold">TCC: </span> {metodologia?.titulo_tcc}
                        </div>
                        <div>
                            <span className="py-2 text-gray-700 font-bold">Nota TCC: </span> {metodologia?.nota_tcc}
                        </div>
                    </div>
                }
            </div>
            <div className="flex items-center justify-center max-h-full pt-10">
                {certificacao?.status_id !== 4 &&
                    <ButtonUpdateDadosAluno certificacao={certificacao} sizeIcon={20}>
                        Atualizar Dados
                    </ButtonUpdateDadosAluno>
                }
                {certificacao?.status_id !== 4 &&
                    <ButtonCreateCertificadoPdf certificacao={certificacao} sizeIcon={20}>
                        Gerar Certificado PDF
                    </ButtonCreateCertificadoPdf>
                }
                {certificacao?.certificado_digitalizado &&
                    <ButtonDownloadCertificadoDigital sizeIcon={20} certificacao={certificacao}>
                        Download do Certificado Digital
                    </ButtonDownloadCertificadoDigital>
                }
                <DownloadDocumentacaoProfessor certificacao={certificacao} sizeIcon={20}>
                    Documentação dos docentes - ZIP
                </DownloadDocumentacaoProfessor>
                <DownloadDocumentacaoAluno certificacao={certificacao} sizeIcon={20}>
                    Documentação do aluno - ZIP
                </DownloadDocumentacaoAluno>
                <ButtonDownloadCsvHistorico certificacao={certificacao} professores={professores.data} sizeIcon={20}>
                    Baixar Histórico - CSV
                </ButtonDownloadCsvHistorico>
                <HelpConvertCsv />
            </div>
            <div className="flex items-center justify-center">
                <div className="w-full overflow-y-auto" style={{ maxHeight: '41rem' }}>
                    <table className="w-full flex flex-row flex-no wrap sm:bg-white rounded-lg sm:shadow-lg my-5">
                        <thead className="text-white">
                            <tr className="flex flex-col flex-no wrap sm:table-row rounded-lg mb-2 sm:mb-0">
                                <th className="p-3 text-left">Disciplina</th>
                                <th className="p-3 text-left">C/H</th>
                                <th className="p-3 text-left">Professor</th>
                                <th className="p-3 text-left">Data Início</th>
                                <th className="p-3 text-left">Data Fim</th>
                                <th className="p-3 text-left">Frequência</th>
                                <th className="p-3 text-left">Média</th>
                            </tr>

                        </thead>

                        <tbody className="flex-1 sm:flex-none">
                            {
                                disciplinas.map(function (disciplina: HistoricoEscolarProps) {

                                    let data_inicio = moment(disciplina.data_inicio).isValid() ? moment(disciplina.data_inicio).format('L') : '';
                                    let data_fim = moment(disciplina.data_fim).isValid() ? moment(disciplina.data_fim).format('L') : '';

                                    return (
                                        <tr key={disciplina.disciplina_id} className="flex flex-col flex-wrap sm:table-row mb-2 sm:mb-0 text-xs">
                                            <td className="td-border">{disciplina.titulo} </td>
                                            <td className="td-border">{disciplina.carga_horaria} </td>
                                            <td className="td-border">
                                                <div>
                                                    {
                                                        disciplina.professor_dedicacao_exclusiva
                                                            ? disciplina.professor_dedicacao_exclusiva.docente_substituto
                                                            : disciplina.professor?.toUpperCase()}
                                                </div>
                                                <div>{getFormacaoProfessor(Number(disciplina?.professor_id), disciplina?.professor_dedicacao_exclusiva)}</div>
                                            </td>
                                            <td className="td-border">{data_inicio} </td>
                                            <td className="td-border">{data_fim} </td>
                                            <td className="td-border">{disciplina.frequencia} </td>
                                            <td className="td-border">{disciplina.nota} </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>


        </>
    )
}

export default HistoricoEscolar;
