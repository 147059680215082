export interface FilterData {
    aluno: string;
    status: [] | number[];
    curso: string;
    data_conclusao_de: string;
    data_conclusao_ate: string;
}

export const filterInitialValues = {
    aluno: '',
    curso: '',
    status: [],
    data_conclusao_de: '',
    data_conclusao_ate: ''
}


export interface FilterProps {
    handleFilter: (filter: FilterData) => void;
}


export interface FilterTeacherData {
    docente: string;
    cpf: string;
    email: string;
}

export const filterTeacherInitialValues = {
    docente: '',
    cpf: '',
    email: ''
}

export interface FilterTeacherProps {
    handleFilter: (filter: FilterTeacherData) => void;
}