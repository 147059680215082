//@ts-nocheck
import React, { useRef } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { certificacaoStatus } from '../../models/StatusModel'
import { CertificacaoStatusProps } from '../../models/StatusModel'
import { ItemProps } from '../../models/FormModel'
import { Input, Select } from '../../components/Form'
import { FilterProps, FilterData } from '../../models/FilterModel'
import { useStatus } from '../../contexts/StatusSelectedContext'

const Filter: React.FC<FilterProps> = (props) => {

  const formRef = useRef<FormHandles>(null)
  const { statusId, setStatusId } = useStatus()

  const handleSubmit: SubmitHandler<FilterData> = filter => {
    setStatusId(null)
    props.handleFilter(filter);
  }


  const itemsMultiSelect = (status: CertificacaoStatusProps[]) => {
    const items: ItemProps[] = [];
    status.map(item => {
      const multiSelect = {
        value: item.id,
        label: item.descricao,
      };
      return items.push(multiSelect);
    });
    return items;
  }


  return (
    <div className="mt-5 md:mt-0 md:col-span-2">
      <Form onSubmit={handleSubmit} ref={formRef}>
        <div className="shadow-lg sm:rounded-md">
          <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 lg:col-span-1">
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="form-label">
                    Aluno ou RA
                  </span>
                  <Input
                    type="text"
                    name="aluno"
                    className="form-input" />
                </div>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="form-label">
                    Curso
                  </span>
                  <Input
                    type="text"
                    name="curso"
                    className="form-input" />
                </div>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="form-label">
                    Status
                  </span>
                  <Select
                    name="status"
                    options={itemsMultiSelect(certificacaoStatus)}
                    isMulti={true}
                    className="form-input"
                    placeholder=""
                    defaultValue={statusId ? [itemsMultiSelect(certificacaoStatus)[statusId - 1]] : []}
                  />
                </div>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="form-label">
                    Data Conclusão De:
                  </span>
                  <Input
                    type="date"
                    name="data_conclusao_de"
                    className="form-input" />
                </div>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="form-label">
                    Data Conclusão Até:
                  </span>
                  <Input
                    type="date"
                    name="data_conclusao_ate"
                    className="form-input" />
                </div>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="form-label">
                    Data Atualizado De:
                  </span>
                  <Input
                    type="date"
                    name="data_atualizado_de"
                    className="form-input" />
                </div>
              </div>
              <div className="col-span-3 lg:col-span-1">
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="form-label">
                    Data Atualizado Até:
                  </span>
                  <Input
                    type="date"
                    name="data_atualizado_ate"
                    className="form-input" />
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button type="submit" className="btn btn-indigo">
              Filtrar
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Filter;