import React from 'react'
import moment from 'moment'
import { useHistory } from "react-router-dom";
import 'moment/locale/pt-br';

moment.locale('pt-br');
interface PageTitleProps {
    title: string;
    children?: React.ReactNode
}

const PageTitle: React.FC<PageTitleProps> = (props) => {

    const history = useHistory();

    return (
        <div className="my-6 lg:my-12 container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between pb-4 border-b border-gray-300">
            <div>
                <h4 className="text-2xl font-bold leading-tight text-gray-800"> {props.title}</h4>
                <ul className="flex flex-col md:flex-row items-start md:items-center text-gray-600 text-sm mt-3">
                    <li className="flex items-center mt-3 md:mt-0">
                        <span className="mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar text-indigo-700" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <rect x={4} y={5} width={16} height={16} rx={2}></rect>
                                <line x1={16} y1={3} x2={16} y2={7}></line>
                                <line x1={8} y1={3} x2={8} y2={7}></line>
                                <line x1={4} y1={11} x2={20} y2={11}></line>
                                <line x1={11} y1={15} x2={12} y2={15}></line>
                                <line x1={12} y1={15} x2={12} y2={18}></line>
                            </svg>
                        </span>
                        <span>  {moment().format('dddd')} , {moment().format('LL')} </span>
                    </li>
                </ul>
            </div>
            <div className="mt-6 lg:mt-0">
                <button
                    onClick={() => history.goBack()}
                    className="mx-2 my-2 bg-white transition duration-150 ease-in-out focus:outline-none hover:bg-gray-100 rounded text-indigo-700 px-6 py-2 text-sm"
                >
                    Voltar
                </button>
            </div>
        </div>
    )
}

export default PageTitle