import React from 'react'
import Divider from '../Divider'


interface PaginationProps {
    info: PaginationData;
    handleChangePage: (page: number) => void;
    handleSetPerPage: (perPage: number) => void;
}


interface PaginationData {
    current_page: number;
    last_page: number;
    from: number;
    to: number;
    total: number;
    per_page: number;
    path: string;
    links: LinksData[];
}

interface LinksData {
    label: string | number;
    url: string;
    active: boolean;
}

const optionsPerPage = [
    {
        value: 15,
        label: '15'
    },
    {
        value: 30,
        label: '30'
    },
    {
        value: 50,
        label: '50'
    }, {
        value: 100,
        label: '100'
    }
]


const Pagination: React.FC<PaginationProps> = (props) => {

    const previous = props.info.links[0]
    const next = props.info.links[props.info.links.length - 1]
    const paginations = props.info.links.filter((link, index) => {
        return index !== 0 && index !== props.info.links.length - 1
    })
    const total = props.info.total


    if (!total) {
        return (
            <div className="bg-white px-4 py-3 flex items-center justify-between border-t sm:px-6 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <div className="flex-1 flex justify-center text-red-400">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width={24} height={24}>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                    <span className="pl-2">
                        Nenhum registro encontrado
                    </span>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="bg-white px-4 py-3 flex items-center justify-between border-t sm:px-6 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <div className="flex-1 flex justify-between sm:hidden">
                    <button
                        onClick={() => props.handleChangePage(props.info.current_page - 1)}
                        disabled={!previous.active}
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                    >
                        Anterior
                    </button>
                    <button
                        onClick={() => props.handleChangePage(props.info.current_page + 1)}
                        disabled={!next.active}
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                    >
                        Next
                    </button>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    <div>
                        <p className="text-sm text-gray-700">
                            Mostrando
                            <span className="font-medium px-1">{props.info.from}</span>
                            até
                            <span className="font-medium px-1">{props.info.to}</span>
                            de
                            <span className="font-medium px-1">{props.info.total}</span>
                            resultados
                        </p>

                        <select
                            onChange={(e) => props.handleSetPerPage(Number(e.target.value))}
                            defaultValue={props.info.per_page}
                            className="focus:ring-indigo-500 focus:border-indigo-500 rounded-none rounded-r-md sm:text-sm border-gray-300"
                        >
                            {optionsPerPage.map((option, index) => {
                                return <option value={option.value} key={index}>{option.label}</option>
                            })}
                        </select>

                    </div>
                    <div>
                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                            {previous.url
                                && <button onClick={() => props.handleChangePage(props.info.current_page - 1)} className="button-pagination-previous">
                                    <span className="sr-only">Anterior</span>
                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            }

                            {paginations.map(link => {
                                return (
                                    <button
                                        key={link.label}
                                        onClick={() => props.handleChangePage(Number(link.label))}
                                        className={`button-pagination ${props.info.current_page === Number(link.label) ? 'bg-indigo-200' : ''}`}
                                    >
                                        {link.label}
                                    </button>
                                )
                            })}


                            {next.url &&
                                <button onClick={() => props.handleChangePage(props.info.current_page + 1)} className="button-pagination-next">
                                    <span className="sr-only">Next</span>
                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            }
                        </nav>
                    </div>
                </div>
            </div>
            <Divider />
        </>
    );
};

export default Pagination;