import { createContext } from "react";
import { UserProps } from "../../models/UserModel";
import { ClientProps } from "../../models/ClientModel";

export interface UserContextType {
    user: UserProps | null;
    client: ClientProps | null;
};

const UserContext = createContext<UserContextType>({} as UserContextType);

export default UserContext;
