import React from 'react';
import { CertificacaoProps } from '../../models/CertificacaoModel'
import ButtonDownloadDocumentacao from './DownloadDocumentacao'
import ButtonDownloadDocumentacaoProfessor from './DownloadDocumentacaoProfessor'
import ButtonDownloadCsvHistorico from './DownloadCsvHistorico'

interface ButtonDownloadsProps {
    certificacao: CertificacaoProps;
    sizeIcon: number;
}

const ButtonDownloads: React.FC<ButtonDownloadsProps> = (props) => {

    const [showButtons, setShowButtons] = React.useState<boolean>(false)
    const handleShowButtons = () => {
        if (showButtons) setShowButtons(false)
    }

    return (
        <div className="relative">
            <div className="flex items-center relative">
                {showButtons && (
                    <ul className="pl-1 w-40 border-r bg-gray-200 absolute rounded right-0 shadow top-0 mt-8 z-40" onMouseLeave={() => handleShowButtons()}>
                        <li className="text-gray-600 text-xs tracking-normal pt-1 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                            <div className="flex items-center">
                                <ButtonDownloadDocumentacao certificacao={props.certificacao} sizeIcon={12} />
                                <span className="ml-2">Documentação Aluno</span>
                            </div>
                        </li>
                        <li className="text-gray-600 text-xs tracking-normal mt-2 pt-1 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none flex items-center">
                            <div className="flex items-center">
                                <ButtonDownloadDocumentacaoProfessor certificacao={props.certificacao} sizeIcon={12} />
                                <span className="ml-2">Documentação Docente</span>
                            </div>
                        </li>
                        <li className="text-gray-600 text-xs tracking-normal mt-2 py-1 hover:text-indigo-700 flex items-center focus:text-indigo-700 focus:outline-none">
                            <div className="flex items-center">
                                <ButtonDownloadCsvHistorico certificacao={props.certificacao} sizeIcon={12} />
                                <span className="ml-2">CSV - Histórico</span>
                            </div>
                        </li>
                    </ul>
                )}
                <button
                    type="button"
                    className="btn bg-purple-500 px-2 mr-1"
                    onClick={() => setShowButtons(!showButtons)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width={props.sizeIcon}>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                    </svg>
                </button>
            </div>
        </div>
    )

};

export default ButtonDownloads;