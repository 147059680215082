import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import { fetcher } from '../services/http'
import useSWR from 'swr'
import { StatusProps, certificacaoStatus, IconStatusProps } from '../models/StatusModel'
import { LoadingScreen as Loading} from '../components/Loading';
import { useStatus } from '../contexts/StatusSelectedContext'
import UserContext from '../contexts/auth/UserContext'


const IconStatus = ({ status }: IconStatusProps) => {
    const icon = certificacaoStatus.filter(icon => icon.id === status.id);
    return (
        <div className={`icon w-14 p-3.5 text-white rounded-full mr-3 ${icon[0]?.bgColor}`} dangerouslySetInnerHTML={{ __html: icon[0]?.icon }}></div>
    );
}

const Dashboard: React.FC = (props) => {

    const { data: status, error } = useSWR('/api/status?certificacoes_resumo=1', fetcher)
    const { setStatusId } = useStatus()
    const { client } = React.useContext(UserContext)
    const handleSetStatus = (data: number) => {
        setStatusId(data);
    }

    // eslint-disable-next-line
    React.useEffect(() => setStatusId(null), [])

    if (!status) {
        return <Loading />
    }

    if (error) {
        return (
            <div>Erro</div>
        )
    }

    return (
        <Layout>
            <PageTitle title='Dashboard' />
            <div className="container mx-auto px-6">
                <div className="grid grid-cols-1 justify-items-center"><h1> {client?.nome_empresa} </h1></div>
                <div className="w-full h-64 rounded">
                    <div className="max-w-7xl w-full mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3 w-full lg:space-x-0 space-y-2 lg:space-y-0 mb-2">
                            {status.data.map((item: StatusProps) => (
                                <div className="w-full" key={item.id}>
                                    <Link to='/acompanhamento' onClick={() => handleSetStatus(item.id)}>
                                        <div className={`widget w-full p-4 rounded-lg bg-white border-l-4 ${certificacaoStatus.filter(icon => icon.id === item.id)[0]?.borderColor}`}>
                                            <div className="flex items-center">
                                                <IconStatus status={item} />
                                                <div className="flex flex-col justify-center">
                                                    <div className="text-lg">{item.qnt_certificacoes}</div>
                                                    <div className="text-sm text-gray-400">{item.status}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Dashboard;