import React, { useEffect, useRef, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    options: {
        id: string;
        value: string;
        label: string;
        description?: string
    }[];
}

export default function CheckboxInput({ name, options, ...rest }: Props) {
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const { fieldName, registerField, defaultValue = [] } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRefs.current,
            getValue: (refs: HTMLInputElement[]) => {
                return refs.filter(ref => ref.checked).map(ref => ref.value);
            },
            clearValue: (refs: HTMLInputElement[]) => {
                refs.forEach(ref => {
                    ref.checked = false;
                });
            },
            setValue: (refs: HTMLInputElement[], values: string[]) => {
                refs.forEach(ref => {
                    if (values.includes(ref.id)) {
                        ref.checked = true;
                    }
                });
            },
        });
    }, [defaultValue, fieldName, registerField]);

    return (
        <div>
            {options.map((option, index) => (
                <div className="flex items-start" key={option.id}>
                    <div className="flex items-center pt-2">
                        <label htmlFor={option.id}>
                            <input
                                defaultChecked={defaultValue.find((dv: string) => dv === option.id)}
                                ref={ref => {
                                    inputRefs.current[index] = ref as HTMLInputElement;
                                }}
                                value={option.value}
                                type="checkbox"
                                id={option.id}
                                {...rest}
                            />
                        </label>
                    </div>
                    <div className="ml-3 text-sm pt-2">
                        <p className="text-gray-500">{option.label}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};