import React, { createContext, useContext } from "react";

import { ProfessorProps as TeacherProps } from '../models/ProfessorModel'


export interface TeacherContextType {
    teacher: TeacherProps | null;
    setTeacher(data: TeacherProps | null): void;
    reload: boolean;
    setReload(reload: boolean): void;
}

interface Props { }

export const TeacherContext = createContext<TeacherContextType>({} as TeacherContextType);

export const TeacherProvider: React.FC<Props> = (props) => {
    const [teacher, setNewTeacher] = React.useState<TeacherProps | null>(null);
    const [reload, setReload] = React.useState(false)
    const setTeacher = (data: TeacherProps | null) => setNewTeacher(data)

    return (
        <TeacherContext.Provider value={{ teacher, setTeacher, reload, setReload }}>{props.children}</TeacherContext.Provider>
    );
};

export const useTeacher = () => useContext(TeacherContext);

