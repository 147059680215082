import Keycloak from 'keycloak-js';
import { AuthClientInitOptions } from '@react-keycloak/core'

const keycloakConfig = JSON.parse(process.env.REACT_APP_KEYCLOAK_JSON!);

const keycloakCfg = {
    url: keycloakConfig['auth-server-url'],
    realm: keycloakConfig['realm'],
    clientId: keycloakConfig['resource'],
}

export const keycloak = Keycloak(keycloakCfg);

export const keycloakProviderInitConfig: AuthClientInitOptions = {
    onLoad: 'login-required',
};

export default keycloakCfg;