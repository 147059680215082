//@ts-nocheck
import React, { useRef } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Input } from '../Form'
import { FilterTeacherData } from '../../models/FilterModel'

const Filter: React.FC<FilterProps> = (props) => {

    const formRef = useRef<FormHandles>(null)

    const handleSubmit: SubmitHandler<FilterTeacherData> = filter => {
        props.handleFilter(filter);
    }


    return (
        <div className="mt-5 md:mt-0 md:col-span-2">
            <Form onSubmit={handleSubmit} ref={formRef}>
                <div className="shadow-lg sm:rounded-md">
                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div className="grid grid-cols-3 gap-6">
                            <div className="col-span-3 lg:col-span-1">
                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="form-label">
                                        Docente
                                    </span>
                                    <Input
                                        type="text"
                                        name="professor"
                                        className="form-input" />
                                </div>
                            </div>
                            <div className="col-span-3 lg:col-span-1">
                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="form-label">
                                        Email
                                    </span>
                                    <Input
                                        type="email"
                                        name="email"
                                        className="form-input" />
                                </div>
                            </div>
                            <div className="col-span-3 lg:col-span-1">
                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="form-label">
                                        CPF
                                    </span>
                                    <Input
                                        type="text"
                                        name="cpf"
                                        className="form-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button type="submit" className="btn btn-indigo">
                            Filtrar
                        </button>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default Filter;