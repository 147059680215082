import React, { createContext, useContext } from "react";

export interface StatusContextType {
    statusId: number | null;
    setStatusId(data: any): void;
}

interface Props { }

export const StatusContext = createContext<StatusContextType>({} as StatusContextType);

export const StatusProvider: React.FC<Props> = (props) => {

    const [statusId, setNewStatusId] = React.useState<number | null>(null);
    function setStatusId(status_id: number | null) { setNewStatusId(status_id) }

    return (
        <StatusContext.Provider value={{ statusId, setStatusId }}>{props.children}</StatusContext.Provider>
    );
};

export const useStatus = () => useContext(StatusContext);

