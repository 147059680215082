import Axios from "axios";
import { keycloak } from "../../models/Keycloak";


export const http = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

const apiRequest = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

apiRequest.interceptors.request.use((config) => {
  if (keycloak?.token) {
    config.headers["Authorization"] = "Bearer " + keycloak?.token;
    return config;
  }
  return new Promise((resolve, reject) => {
    keycloak.onAuthSuccess = () => {
      config.headers["Authorization"] = "Bearer " + keycloak?.token;
      resolve(config);
    };
    keycloak.onAuthError = () => {
      reject(config);
    };
  });
});

apiRequest.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status) {
      if (error.response.status === 401) document.location.replace('/');
      if (error.response.status === 422) {
        return Promise.reject(error.response.data);
      }
    }
  }
);

export default apiRequest;

export const fetcher = (url: string) => apiRequest.get(url).then(res => res.data);