import React, { useState } from "react"
import { menus } from '../../models/MenuModel'
import UserContext from '../../contexts/auth/UserContext'
import { useKeycloak } from '@react-keycloak/web'
import { Link, useLocation } from 'react-router-dom'
import keycloakCfg from '../../models/Keycloak'

const Navbar: React.FC = () => {

  const [profile, setProfile] = useState(false)
  const { user, client } = React.useContext(UserContext)
  const { keycloak } = useKeycloak()
  const location = useLocation()
  const { url, realm, clientId } = keycloakCfg;

  return (
    <>
      <div className="h-full flex items-center">
        <div className="mr-10 flex items-center bg-gray-700 shadow-2xl rounded px-4 py-4">
          <img
            src={client?.logo}
            alt="Logotipo"
            className="mt-3 max-h-20 w-auto"
          />
          <h3 className="text-lg text-gray-50 ml-3 hidden lg:block mt-4"> Sistema - Certificações</h3>
        </div>
        <ul className="pr-12 xl:flex items-center h-full hidden">
          {menus.map((menu, index_menu) => (
            <li key={index_menu} className={location.pathname === menu.url ? 'menu_active' : 'menu'}>
              <Link to={menu.url}>
                {menu.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="h-full xl:flex items-center justify-end hidden">
        <div className="w-full h-full flex items-center">
          <div className="w-full pr-10 h-full flex items-center border-r">
            <div className="relative w-full">
              <div className="text-gray-500 absolute ml-3 inset-0 m-auto w-4 h-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={16} height={16} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <circle cx={10} cy={10} r={7} />
                  <line x1={21} y1={21} x2={15} y2={15} />
                </svg>
              </div>
              <input className="border border-gray-100 focus:outline-none focus:border-indigo-700 w-56 rounded text-sm text-gray-500 bg-gray-100 pl-8 py-2" type="text" placeholder="nome do aluno" />
            </div>
          </div>
          <div className="w-full h-full flex">
            <div className="w-32 h-full flex items-center justify-center border-r cursor-pointer text-gray-600">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bell" width={28} height={28} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
              </svg>
            </div>
            <div aria-haspopup="true" className="cursor-pointer w-full flex items-center justify-end relative" onClick={() => setProfile(!profile)}>
              {profile ? (
                <ul className="p-2 w-40 border-r bg-white absolute rounded z-40 left-0 shadow mt-52">
                  <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                    <div className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <circle cx={12} cy={7} r={4} />
                        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                      </svg>
                      <span className="ml-2">
                        <a href={`${url}/realms/${realm}/account?referrer=${clientId}&redirect_uri=http://localhost:3000/certificacoes/configuracoes`}
                          target="_blank"
                          rel="noreferrer"
                        >Meu Perfil</a>
                      </span>
                    </div>
                  </li>
                  <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-2 py-2 hover:text-indigo-700 flex items-center focus:text-indigo-700 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-settings" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                      <circle cx={12} cy={12} r={3} />
                    </svg>
                    <span className="ml-2" onClick={() => keycloak?.logout()}>Logout</span>
                  </li>
                </ul>
              ) : (
                ""
              )}

              <div className="m-1 mr-2 shadow-solid rounded h-10 w-10 object-cover relative flex justify-center items-center bg-gray-500 text-xl text-white uppercase">{user?.name?.charAt(0)}</div>
              <p className="text-gray-800 text-sm ml-2">{user?.name.split(' ').slice(0, 1)}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar;
