import React from 'react';
import { useSnackbar } from 'notistack'
import valuesIn from 'lodash/valuesIn';

import { useUser } from '../../contexts/auth/UserProvider'
import { CertificacaoProps } from '../../models/CertificacaoModel'
import apiRequest from '../../services/http';

interface ButtonUpdateDadosAlunoProps {
    certificacao: CertificacaoProps | null;
    sizeIcon: number;
}

const ButtonUpdateDadosAluno: React.FC<ButtonUpdateDadosAlunoProps> = (props) => {

    const [loading, setLoading] = React.useState(false)
    const { client } = useUser();
    const { enqueueSnackbar } = useSnackbar();

    async function handleGetUpdateDataStudent(certificacao: CertificacaoProps | null) {

        if (!certificacao) return

        setLoading(true);
        const url = `${process.env.REACT_APP_SCHEME}://${client?.dominio_sistema}/microservico/concluinte/${certificacao.pessoa_id}/${certificacao.pessoa_aprovada_id}`
        try {
            const { data } = await apiRequest.get(url);
            handleUpdateStudent(data)
        } catch (error) {
            enqueueSnackbar('Erro ao atualizar dados!', { variant: 'error' });
        }


        setLoading(false);
    }

    async function handleUpdateStudent(formData: any) {

        await apiRequest.put(`/api/certificacoes/${props.certificacao?.id}/aluno`, formData)
            .then(response => enqueueSnackbar('Atualizado com sucesso.', { variant: 'success' }))
            .catch(error => {
                let message = 'Erro ao atualizar dados! ';
                if (error?.errors) message += valuesIn(error?.errors)[0];
                enqueueSnackbar(message, { variant: 'error' });
            });

    }

    return (
        <button
            className="btn bg-yellow-500 px-2 mr-1"
            onClick={() => handleGetUpdateDataStudent(props.certificacao)}
            title="Atualizar dados do aluno"
            disabled={loading}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className={loading ? 'animate-spin' : ''} fill="none" viewBox="0 0 24 24" stroke="currentColor" width={props.sizeIcon}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>{props.children}
        </button>
    );
};

export default ButtonUpdateDadosAluno;