import React, { useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { useSnackbar } from 'notistack'

import { useCertificacaoPreview } from '../../contexts/CertificacaoPreviewSelected'
import { useCertificacao } from '../../contexts/CertificacaoSelectedContext';
import { EditStatusCertificacaoDataSubmit, EditStatusCertificacaoProps } from '../../models/StatusModel'
import { Input } from '../../components/Form'


const Preview: React.FC<EditStatusCertificacaoProps> = (props) => {

    const [disapprove, setDisapprove] = useState<boolean>(false)
    const { certificacaoPreview } = useCertificacaoPreview()
    const { certificacao } = useCertificacao()
    const statusChecks = [10, 11];
    const formRef = useRef<FormHandles>(null)
    const { enqueueSnackbar } = useSnackbar()


    const getNextStatus = () => {
        if (disapprove) return 9;
        if (certificacao?.status_id === 1) return 10;
        if (certificacao?.status_id === 10) return 11;
        if (certificacao?.status_id === 11) return 12;
    }

    const handleSubmit: SubmitHandler = data => {

        const justificativa = data?.justificativa ? data.justificativa : null;
        const status_id = getNextStatus();

        if (disapprove && !justificativa) {
            enqueueSnackbar('Erro o campo justificativa é obrigatório', { variant: 'error' });
            return;
        }

        const statusEditData = {
            certificacao_id: certificacao?.id,
            status_id: status_id,
        } as EditStatusCertificacaoDataSubmit

        if (justificativa) statusEditData.justificativa = justificativa

        props.handleSubmit(statusEditData)
    }

    const getDescriptionButton = (status_id: number) => {
        return status_id === 10 ? 'Aprovar para última conferência' : 'Aprovar';
    }


    return (
        <>
            <Form onSubmit={handleSubmit} ref={formRef}>
                {certificacao?.status_id === 1 && <button type="submit" className="btn btn-indigo m-4">Enviar para Conferência</button>}
                {certificacao?.status_id && statusChecks.includes(certificacao.status_id) && !disapprove &&
                    <>
                        <button type="submit" className="btn btn-indigo m-4">{getDescriptionButton(certificacao.status_id)}</button>
                        <button type="button" onClick={() => setDisapprove(true)} className="btn btn-orange">Desaprovar</button>
                    </>
                }
                {disapprove &&
                    <div className="flex-1">
                        <div className="mt-4 flex">
                            <span className="label-form">
                                Justificativa:
                            </span>
                            <Input
                                type='text'
                                name="justificativa"
                                className='form-input'
                            />
                            <button type="submit" className="btn btn-orange m-4">Desaprovar</button>
                            <button type="button" onClick={() => setDisapprove(false)} className="btn text-black bg-trueGray-300 m-4">Cancelar</button>
                        </div>
                    </div>
                }
            </Form>

            <div className='h-full w-full' dangerouslySetInnerHTML={{ __html: `<iframe height='100%' width='100%' src='${certificacaoPreview}' sandbox='allow-scripts allow-modals' />` }} />
        </>
    );
};

export default Preview;
