import React from 'react';
import { useSnackbar } from 'notistack'
import valuesIn from 'lodash/valuesIn';

import apiRequest from '../../services/http';
import { CertificacaoProps } from '../../models/CertificacaoModel'
import { useCertificacao } from '../../contexts/CertificacaoSelectedContext';

interface RectificationCertificacaoButtonProps {
    sizeIcon: number;
    certificacao: CertificacaoProps | null;
}

const RectificationCertificacaoButton: React.FC<RectificationCertificacaoButtonProps & React.HTMLProps<HTMLButtonElement>> = (props) => {

    const [loading, setLoading] = React.useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const { certificacao, setCertificacao } = useCertificacao()

    async function handleClick() {
        setLoading(true);
        try {
            const { data } = await apiRequest.put(`/api/certificacoes/${props.certificacao?.id}/retificacao`);
            if(certificacao) {
                setCertificacao(data.data);
            }
            enqueueSnackbar('Certificado enviado para retificação com sucesso.', { variant: 'success' });
        } catch (error) {
            let message = 'Erro ao atualizar dados! ';
            if (error?.errors) message += valuesIn(error?.errors)[0];
            enqueueSnackbar(message, { variant: 'error' });
        }
        setLoading(false);
    }


    return (
        <button
            className="btn bg-red-500 px-2 mr-1"
            title="Retificar esse certificado"
            onClick={() => handleClick()}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className={loading ? 'animate-spin' : ''} fill="none" viewBox="0 0 24 24" stroke="currentColor" width={props.sizeIcon}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            {props.children}
        </button>
    );
};

export default RectificationCertificacaoButton;