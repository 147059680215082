import React from 'react';
import { useSnackbar } from 'notistack'

import { useUser } from '../../contexts/auth/UserProvider'
import { CertificacaoProps } from '../../models/CertificacaoModel'
import apiRequest from '../../services/http';
import { useLoading } from '../../contexts/LoadingContext'

interface ButtonDownloadDocumentacaoProps {
    certificacao: CertificacaoProps | null;
    sizeIcon: number;
}


const ButtonDownloadDocumentacao: React.FC<ButtonDownloadDocumentacaoProps> = (props) => {

    const [loading, setLoading] = React.useState(false)
    const { setLoading: setLoadingGlobal } = useLoading();
    const { client } = useUser();
    const { enqueueSnackbar } = useSnackbar();

    function handleDownload(certificacao: CertificacaoProps | null) {

        if (!certificacao) return

        const fileName = `documentacao_${certificacao.pessoa_id}.zip`
        const url = `${process.env.REACT_APP_SCHEME}://${client?.dominio_sistema}/microservico/concluinte/${certificacao.pessoa_id}/${certificacao.pessoa_aprovada_id}/download/documentacao`

        setLoading(true);
        setLoadingGlobal(true);

        apiRequest({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }).catch((erro: Blob) => {
            erro.text().then(text => enqueueSnackbar(`Erro na operação. ${JSON.parse(text).status}`, { variant: 'error' }));
        }).finally(() => {
            setLoading(false)
            setLoadingGlobal(false)
        });
    }


    return (
        <button
            className="btn bg-purple-500 px-2 mr-1"
            onClick={() => handleDownload(props.certificacao)}
            title="Baixar Download - Documentação Obrigatória em ZIP"
            disabled={loading}
        >
            <svg className={loading ? 'animate-spin' : ''} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width={props.sizeIcon}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>{props.children}
        </button>
    );
};

export default ButtonDownloadDocumentacao;