import React, { useState } from "react";
import Navbar from '../Navbar';
import NavbarMobile from "../Navbar/NavBarMobile";


const Header: React.FC = () => {

    const [show, setShow] = useState(false);

    return (
        <>
            {/* Navigation starts */}
            {/* Mobile */}
            <div className={show ? "w-full h-full absolute z-40  transform  translate-x-0 " : "w-full h-full absolute z-40 transform -translate-x-full"}>
                <div className="bg-gray-800 opacity-50 inset-0 fixed w-full h-full" onClick={() => setShow(!show)} />
                <div className="w-64 z-20 absolute left-0 top-0 bg-white shadow flex-col justify-between transition duration-150 ease-in-out h-full">
                    <NavbarMobile setClose={setShow} />
                </div>
            </div>
            {/* Desktop */}
            <nav className="w-full mx-auto bg-white shadow">
                <div className="container px-6 justify-between h-16 flex items-center lg:items-stretch mx-auto">
                    <Navbar />
                    <div className="visible xl:hidden flex items-center relative">
                        <svg onClick={() => setShow(!show)} aria-label="Main Menu" aria-haspopup="true" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-menu" width={32} height={32} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <line x1={4} y1={8} x2={20} y2={8} />
                            <line x1={4} y1={16} x2={20} y2={16} />
                        </svg>
                    </div>
                </div>
            </nav>
            {/* Navigation ends */}
        </>
    );
};

export default Header;
