import React from 'react';
import { ProfessorProps } from '../../models/ProfessorModel'
import ButtonUpdateTeacherApi from '../ActionButton/UpdateTeacherApi'
import ViewDocumentacao from '../ActionButton/ViewDocumentacao'



interface ActionButtonProps {
    teacher: ProfessorProps;
    handleActionClick: (action: string, teacher: ProfessorProps) => void;
}

const sizeIcon = 10;

const ActionButton: React.FC<ActionButtonProps> = (props) => {

    return (
        <div className="block" aria-hidden="true">
            <div className="py-2 sm:py-5 flex flex-nowrap">
                <ButtonUpdateTeacherApi teacher={props.teacher} sizeIcon={sizeIcon} />
                <ViewDocumentacao onClick={() => props.handleActionClick('view_documentacao', props.teacher)} sizeIcon={sizeIcon} />
            </div>
        </div>
    );
};

export default ActionButton;