import React from 'react';
import { CSVLink } from "react-csv";
import maxByLodash from 'lodash/maxBy';
import firstLodash from 'lodash/first';
import moment from 'moment'

import { getUfById } from '../../models/UfModels'
import { useLoading } from '../../contexts/LoadingContext'
import {
    CertificacaoProps,
    HistoricoEscolarCsvProps,
    HistoricoEscolarProps } from '../../models/CertificacaoModel'
import {
    ProfessorProps,
    ProfessorDedicacaoExclusivaProps,
    TitulosDocentesProps} from '../../models/ProfessorModel'

interface ButtonDownloadCsvHistoricoProps {
    certificacao: CertificacaoProps | null;
    professores?: ProfessorProps[];
    sizeIcon: number;
}


const ButtonDownloadCsvHistorico: React.FC<ButtonDownloadCsvHistoricoProps> = (props) => {

    const [loading, setLoading] = React.useState(false)
    const disciplinas = props.certificacao?.historico_escolar ? JSON.parse(props.certificacao?.historico_escolar) : [] as HistoricoEscolarProps[];
    const fileName = `${props.certificacao?.pessoa_id}_historico_escolar.csv`
    const { setLoading: setLoadingGlobal } = useLoading();

    function getFormacaoProfessor(professor_id: number | null, professor_dedicacao_exclusiva: null | ProfessorDedicacaoExclusivaProps) {

        if (professor_dedicacao_exclusiva) {
            const grau = maxByLodash(professor_dedicacao_exclusiva.titulos_docentes_substituto, 'grau') as TitulosDocentesProps;
            const titulacao = `${grau?.descricao} em ${grau?.curso} pela ${grau?.instituicao} ${getUfById(Number(grau?.uf_id))?.uf}/${moment(grau?.data_conclusao).format('Y')}`;
            const documento = professor_dedicacao_exclusiva.documento_docente;
            return { titulacao, documento };
        }

        const teachers = props.professores ? props.professores : [];
        const teacher = firstLodash(teachers?.filter((teacher) => {
            return Number(teacher.pessoa_id) === Number(professor_id);
        }));

        if (teacher) {
            const titulos_docentes = JSON.parse(teacher.titulos_docentes) as TitulosDocentesProps[];
            const grau = maxByLodash(titulos_docentes, 'grau');
            const titulacao = `${grau?.descricao} em ${grau?.curso} pela ${grau?.instituicao} ${grau?.uf_id ? getUfById(Number(grau.uf_id))?.uf : '--'}/${moment(grau?.data_conclusao).format('Y')}`
            const documento = teacher.cpf;

            return {
                titulacao, documento
            }

        }
        return {
            titulacao:'',
            documento:''
        };
    }

    const historico_escolar = props.professores
        ? disciplinas.map( (disciplina:HistoricoEscolarCsvProps) => {
                const professor_info = getFormacaoProfessor(disciplina.professor_id, disciplina.professor_dedicacao_exclusiva);
                disciplina.documento_professor = professor_info.documento;
                disciplina.titulacao_professor = professor_info.titulacao;
                return disciplina;
          })
        : disciplinas;

    const headers = [
        { label: "Disciplina", key: "titulo" },
        { label: "Carga Horária", key: "carga_horaria" },
        { label: "Professor", key: "professor" },
        { label: "Titulação", key: "titulacao_professor" },
        { label: "Documento Professor", key: "documento_professor" },
        { label: "Data Início", key: "data_inicio" },
        { label: "Data Fim", key: "data_fim" },
        { label: "Frequência", key: "frequencia" },
        { label: "Média", key: "nota" }
    ];

    return (
        <CSVLink
            data={historico_escolar}
            headers={headers}
            onClick={() => {
                setLoading(true);
                setLoadingGlobal(true);
                setTimeout(() => {
                    setLoading(false);
                    setLoadingGlobal(false);
                }, 1000)
            }}
            className="btn bg-purple-700 px-2 mr-1"
            title="Baixar download - Histórico Escolar em CSV"
            filename={fileName}
        >
            <svg className={loading ? 'animate-spin' : ''} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width={props.sizeIcon}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>{props.children}
        </CSVLink>
    );
};

export default ButtonDownloadCsvHistorico;
