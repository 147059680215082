import React from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { useSnackbar } from 'notistack';


import { useUser } from '../../contexts/auth/UserProvider'
import { Input, TextArea, FileInput } from '../../components/Form'
import apiRequest from '../../services/http'
//import { optionsEditor } from '../../models/SunEditorModel'


const FormCompany: React.FC = (props) => {

    const [loading, setLoading] = React.useState<boolean>(false);
    const { client } = useUser()
    const formRef = React.useRef<FormHandles>(null)
    const { enqueueSnackbar } = useSnackbar();

    //const heightEditor = { height: '100' }
    //const optionsEditorPeronal = { ...optionsEditor, ...heightEditor }

    const handleSubmit: SubmitHandler = data => {

        const formData = new FormData();
        if (data.certificado_fundo) formData.append("certificado_fundo", data.certificado_fundo)
        if (data.certificado_fundo_verso) formData.append("certificado_fundo_verso", data.certificado_fundo_verso)
        formData.append('_method', 'PUT') //Laravel com formdata
        formData.append("empresa_dominio", data.empresa_dominio)
        formData.append("decreto_resolucao", data.decreto_resolucao)

        const config = { headers: { 'content-type': 'multipart/form-data' } }

        setLoading(true)
        apiRequest.post(`/api/cliente/${client?.id}`, formData, config)
            .then(result => {
                enqueueSnackbar('Alteração efetuada com sucesso', { variant: 'success' })
            })
            .catch(error => { enqueueSnackbar('Erro ao tentar salvar', { variant: 'error' }) })
            .finally(() => setLoading(false))
    }

    return (
        <Form onSubmit={handleSubmit} ref={formRef}>
            <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 sm:col-span-2">
                            <label htmlFor="empresa_dominio" className="block text-sm font-medium text-gray-700">
                                Dominio
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                                    https://
                                </span>
                                <Input type="text" name="empresa_dominio" id="empresa_dominio" className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300" value={client?.dominio} disabled={true} />
                            </div>
                        </div>
                    </div>
                    {/*

                    <div>
                        <label htmlFor="texto" className="block text-sm font-medium text-gray-700">
                            Texto Certificado Frente
                        </label>
                        <div className="mt-1">
                            <EditorHtml
                                name="texto"
                                optionsEditor={optionsEditorPeronal}
                                defaultValue={''}
                                initValue={''}
                            />

                        </div>
                        <p className="mt-2 text-sm text-gray-500">
                            Texto que ira acompanhar o certificado referente a resolução da instituição.
                        </p>
                    </div>
                    */}

                    <div>
                        <label htmlFor="decreto_resolucao" className="block text-sm font-medium text-gray-700">
                            Resolução/Decreto
                        </label>
                        <div className="mt-1">
                            <TextArea
                                name="decreto_resolucao"
                                rows={3}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                                defaultValue={client?.decreto_resolucao}
                            />
                        </div>
                        <p className="mt-2 text-sm text-gray-500">
                            Texto que ira acompanhar o certificado referente a resolução da instituição.
                        </p>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                        </label>
                        <div className="mt-1 flex justify-center px-6 pt-2 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                            <div className="space-y-1 text-center">
                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <div className="flex text-sm text-gray-600">
                                    <label htmlFor="certificado_fundo" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                        <span>Upload da imagem de fundo</span>
                                        <FileInput id="certificado_fundo" name="certificado_fundo" type="file" className="sr-only" />
                                    </label>
                                    <p className="pl-1">ou arraste e solte</p>
                                </div>
                                <p className="text-xs text-gray-500">
                                    PNG, JPG, GIF up to 10MB
                                </p>
                            </div>
                        </div>

                        <div className="py-4">
                            <label className="block text-sm font-medium text-gray-700">
                                Imagem de fundo do verso do certificado
                            </label>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                            </label>
                            <div className="mt-1 flex justify-center px-6 pt-2 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                <div className="space-y-1 text-center">
                                    <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <div className="flex text-sm text-gray-600">
                                        <label htmlFor="certificado_fundo_verso" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                            <span>Upload da imagem de fundo do verso</span>
                                            <FileInput id="certificado_fundo_verso" name="certificado_fundo_verso" type="file" accept="image/*" className="sr-only" />
                                        </label>
                                        <p className="pl-1">ou arraste e solte</p>
                                    </div>
                                    <p className="text-xs text-gray-500">
                                        PNG, JPG, GIF up to 10MB
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button type="submit" className="btn btn-indigo" disabled={loading}>
                        {loading && <>&nbsp;<svg className="text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width={24} height={24}>
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg></>}
                        Salvar
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default FormCompany;