import React from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import { useUser } from '../../contexts/auth/UserProvider'
import { certificacaoStatus, CertificacaoStatusProps } from '../../models/StatusModel'
//import apiRequest from '../../services/http'
import CheckBoxInput from '../../components/Form/CheckBoxInput'
import { CheckBoxItemProps } from '../../models/FormModel'

const FormNotification: React.FC = (props) => {

    //const [loading, setLoading] = React.useState<boolean>(false)
    const formRef = React.useRef<FormHandles>(null)
    const { client } = useUser()

    const itemsStatusCheckBox = (status: CertificacaoStatusProps[]) => {
        const items: CheckBoxItemProps[] = [];
        status.map(item => {
            const itemCheckBox = {
                id: item.id.toString(),
                value: item.id.toString(),
                label: item.descricao,
            };
            return items.push(itemCheckBox);
        });
        return items;
    }
    const checkBoxStatus = itemsStatusCheckBox(certificacaoStatus);

    const handleSubmit: SubmitHandler = data => {

        console.log(client)
        //setLoading(true)
        /*apiRequest.post(`/api/cliente/${client?.id}`, data)
            .then(result => console.log(result))
            .catch(error => console.log(error.data))
            .finally(() => setLoading(false))*/
    }


    return (
        <Form onSubmit={handleSubmit} ref={formRef}>
            <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <fieldset>
                        <legend className="text-base font-medium text-gray-900">Por Email</legend>
                        <div className="mt-4 space-y-4">

                            <CheckBoxInput
                                options={checkBoxStatus}
                                name="notifications"
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                        </div>
                    </fieldset>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                        className="btn btn-indigo"
                        type="submit"
                    >
                        Salvar
                </button>
                </div>
            </div>
        </Form>
    );
};

export default FormNotification;