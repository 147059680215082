import React from 'react'

const MainContent: React.FC = (props) => {
    return (
        <>
            {props.children}
        </>
    );
};

export default MainContent;