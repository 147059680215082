export interface StatusProps {
    id: number;
    status: string;
    qnt_certificacoes: number
}

export interface IconStatusProps {
    status: StatusProps
}

export interface EditStatusCertificacaoDataSubmit {
    certificacao_id: string;
    status_id: number;
    data_conclusao?: string;
    area?: string;
    carga_horaria?: number;
    codigo_rastreamento?: string;
    data_emissao?: string;
    data_status?: string;
    data_postagem?: string;
    data_registro?: string;
    numero_registro?: string;
    numero_livro?: string;
    numero_folha?: string;
    certificado_digitalizado?: any;
    justificativa?: string;
}

export interface CertificacaoStatusProps {
    id: number;
    descricao: string;
    bgColor: string;
    borderColor: string;
    icon: string;
}


export const certificacaoStatus = [
    {
        id: 1,
        descricao: 'Aptos para certificação',
        notificar: 'agente',
        bgColor: 'bg-yellow-400',
        borderColor: 'border-yellow-400',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"></path><path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"></path></svg>'
    },
    {
        id: 2,
        descricao: 'Enviados para Impressão',
        notificar: 'mantenedora',
        bgColor: 'bg-purple-400',
        borderColor: 'border-purple-400',
        icon: '<svg fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg>'
    },
    {
        id: 3,
        descricao: 'Retornados da Impressão',
        notificar: 'aluno',
        bgColor: 'bg-green-300',
        borderColor: 'border-green-300',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path d="M8.445 14.832A1 1 0 0010 14v-2.798l5.445 3.63A1 1 0 0017 14V6a1 1 0 00-1.555-.832L10 8.798V6a1 1 0 00-1.555-.832l-6 4a1 1 0 000 1.664l6 4z" /></svg>'
    },
    {
        id: 4,
        descricao: 'Finalizado',
        notificar: 'aluno',
        bgColor: 'bg-green-400',
        borderColor: 'border-green-400',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" /></svg>'
    },
    {
        id: 5,
        descricao: 'Pendente - Documentação Aluno',
        notificar: 'aluno',
        bgColor: 'bg-pink-600',
        borderColor: 'border-pink-600',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" /></svg>'
    },
    {
        id: 6,
        descricao: 'Pendente - Frequência Aluno',
        notificar: 'aluno',
        bgColor: 'bg-pink-300',
        borderColor: 'border-pink-300',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" /></svg>'
    },
    {
        id: 7,
        descricao: 'Pendente - Documentação Docente',
        notificar: 'professor',
        bgColor: 'bg-red-800',
        borderColor: 'border-red-800',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z" /></svg>'
    },
    {
        id: 8,
        descricao: 'Pendente - Percentual Mínimo de Docente',
        notificar: 'agente',
        bgColor: 'bg-pink-900',
        borderColor: 'border-pink-900',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.496 2.132a1 1 0 00-.992 0l-7 4A1 1 0 003 8v7a1 1 0 100 2h14a1 1 0 100-2V8a1 1 0 00.496-1.868l-7-4zM6 9a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1zm3 1a1 1 0 012 0v3a1 1 0 11-2 0v-3zm5-1a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1z" clipRule="evenodd" /></svg>'
    },
    {
        id: 9,
        descricao: 'Pendente - Retornado para Retificação',
        notificar: 'agente',
        bgColor: 'bg-red-700',
        borderColor: 'border-red-700',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>'
    },
    {
        id: 10,
        descricao: 'Pendente - Primeira Conferência',
        notificar: 'agente',
        bgColor: 'bg-cyan-500',
        borderColor: 'border-cyan-500',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" /></svg>'
    },
    {
        id: 11,
        descricao: 'Pendente - Última Conferência',
        notificar: 'agente',
        bgColor: 'bg-cyan-700',
        borderColor: 'border-cyan-700',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" /></svg>'
    },
    {
        id: 12,
        descricao: 'Apto para Emissão',
        notificar: 'agente',
        bgColor: 'bg-orange-400',
        borderColor: 'border-orange-400',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" /></svg>'
    }
];

export const getCertificadoStatus = (statusCertificacao: number) => certificacaoStatus.filter(status => status.id === statusCertificacao)

export interface EditStatusCertificacaoProps {
    handleSubmit: (data: EditStatusCertificacaoDataSubmit) => void;
}
