import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useField } from '@unform/core';

interface Props {
    name: string;
}

interface InputRefProps extends HTMLInputElement {
    acceptedFiles: File[];
}

export default function ReactDropzoneInput({ name }: Props) {
    const inputRef = useRef<InputRefProps>(null);
    const { fieldName, registerField, defaultValue = [] } = useField(name);

    const [acceptedFiles, setAcceptedFiles] = useState<File[]>(defaultValue);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: undefined,
        onDrop: onDropAcceptedFiles => {
            if (inputRef.current) {
                inputRef.current.acceptedFiles = onDropAcceptedFiles;
                setAcceptedFiles(onDropAcceptedFiles);
            }
        },
    });

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: (ref: InputRefProps) => {
                return ref.acceptedFiles || [];
            },
            clearValue: (ref: InputRefProps) => {
                ref.acceptedFiles = [];
                setAcceptedFiles([]);
            },
            setValue: (ref: InputRefProps, value) => {
                ref.acceptedFiles = value;
                setAcceptedFiles(value);
            },
        });
    }, [fieldName, registerField]);

    return (
                        <div {...getRootProps()} onClick={() => inputRef.current?.click()}>
                            <input {...getInputProps()} accept="image/*" ref={inputRef} />

                            {acceptedFiles.length !== 0 && (
                                <ul>
                                    {acceptedFiles.map(file => (
                                        <li key={file.name}>{file.name}</li>
                                    ))}
                                </ul>
                            )}

        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div className="space-y-1 text-center">
                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <div className="flex text-sm text-gray-600">
                    <label htmlFor="certificado_digitalizado" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                        <span>Upload do certificado digitalizado do aluno</span>
                            {isDragActive ? (
                                <p> Soltar ...</p>
                            ) : (
                                <p>Você pode também arrastar o arquivo aqui.</p>
                            )}
                    </label>
                </div>
                <p className="text-xs text-gray-500">
                    PNG, JPG, GIF , PDF - 10MB
                                                </p>
            </div>
        </div>
      </div>
  );
};
